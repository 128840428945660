import React, { useState } from 'react';

const Calculadora = () => {
  const [numeroCajas, setNumeroCajas] = useState('');
  const [cantidadPorCaja, setCantidadPorCaja] = useState('');
  const [piezasSueltas, setPiezasSueltas] = useState('');
  const [total, setTotal] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const totalCajas = parseInt(numeroCajas || 0) * parseInt(cantidadPorCaja || 0);
    const totalPiezas = totalCajas + parseInt(piezasSueltas || 0);
    setTotal(totalPiezas);
  };

  const handleClear = () => {
    setNumeroCajas('');
    setCantidadPorCaja('');
    setPiezasSueltas('');
    setTotal(0);
  };

  return (
    <div className="flex items-center justify-center w-full h-full p-4 bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label className="block text-lg font-medium text-gray-700">Número de Cajas📦📦</label>
            <input
              type="number"
              inputMode="numeric"
              className="mt-2 block w-full p-4 text-lg border border-gray-300 rounded-md"
              placeholder="Ingresa aquí el NUMERO DE CAJAS"
              value={numeroCajas}
              onChange={(e) => setNumeroCajas(e.target.value)}
            />
          </div>
          
          <div>
            <label className="block text-lg font-medium text-gray-700">Cantidad de piezas por Caja📦⚽</label>
            <input
              type="number"
              inputMode="numeric"
              className="mt-2 block w-full p-4 text-lg border border-gray-300 rounded-md"
              placeholder="Ingresa aquí la CANTIDAD POR CAJA"
              value={cantidadPorCaja}
              onChange={(e) => setCantidadPorCaja(e.target.value)}
            />
          </div>
          
          <div>
            <label className="block text-lg font-medium text-gray-700">Piezas sueltas⚽ </label>
            <input
              type="number"
              inputMode="numeric"
              className="mt-2 block w-full p-4 text-lg border border-gray-300 rounded-md"
              placeholder="Ingresa aquí las PIEZAS SUELTAS"
              value={piezasSueltas}
              onChange={(e) => setPiezasSueltas(e.target.value)}
            />
          </div>

          <div>
            <button type="submit" className="w-full py-4 px-6 bg-orange-400 text-white text-lg font-medium rounded-md">CALCULAR</button>
          </div>
          
          <div>
            <button type="button" onClick={handleClear} className="w-full py-4 px-6 bg-gray-400 text-white text-lg font-medium rounded-md">LIMPIAR</button>
          </div>
        </form>
        <div className="mt-4 text-3xl font-bold text-gray-900">
          TOTAL: {total}
        </div>
      </div>
    </div>
  );
}

export default Calculadora;
