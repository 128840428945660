import React from 'react';
import Calculadora from './components/Calculadora';

function App() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <Calculadora />
    </div>
  );
}

export default App;
